import React from "react"
import Seo from "../../components/seo"

import PhotoGallery from "../../components/Content/Gallery"

export default props => (
  <React.Fragment>
    <Seo title={`Gallery`} />
    <PhotoGallery location={props.location} />
  </React.Fragment>
)
