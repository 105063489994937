import React from "react"
import Layout from "../../layout"
import PagesHero from "../../pages"
import Section from "../../section"

import { sum, chunk } from "lodash"

import PrimaryTitle from "../../PrimaryTitle"

/** @jsx jsx */
import { jsx } from "@emotion/core"

import { FormattedMessage } from "react-intl"
import { useStaticQuery, graphql } from "gatsby"

import Gallery from "react-photo-gallery"
import Img from "gatsby-image"

const PhotoGallery = props => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { relativePath: { regex: "/gallery/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const photos = data.images.edges.map(p => p.node.childImageSharp.fluid)
  const rows = chunk(photos, 3, 4)

  return (
    <React.Fragment>
      <Layout location={props.location}>
        <PagesHero>
          <div>
            <div className="has-text-centered mb-6" css={{ marginTop: 60 }}>
              <PrimaryTitle textId="Gallery" />
              <FormattedMessage id="ga_titleText" />
            </div>

            <section>
              {rows.map(row => {
                const rowAspectRatioSum = sum(
                  row.map(image => image.aspectRatio)
                )

                return row.map((image, index) => (
                  <div
                    key={index.toString()}
                    className={`image`}
                    css={{
                      width: `${(image.aspectRatio / rowAspectRatioSum) *
                        100}%`,
                      display: `inline-block`,
                      transition: `filter 0.3s`,
                      "&:hover": {
                        filter: "brightness(55.5%)",
                      },
                    }}
                  >
                    <Img fluid={image} />
                  </div>
                ))
              })}
            </section>
          </div>
        </PagesHero>
        <br />
        <br />
      </Layout>
    </React.Fragment>
  )
}

export default PhotoGallery
